import type {DefaultSettings} from '../models/DefaultSettings'

export const useDefaultSettingsStore = defineStore('defaultSettings', {
    state: (): { settings: DefaultSettings } => ({
        settings: {} as DefaultSettings
    }),
    actions: {
        setSettings(settings: DefaultSettings) {
            this.settings = settings
        }
    }
})